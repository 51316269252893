.navbar {
  background-color: #F0E68C;
}

.navbar-actions {
  color: #1B3D2F;
}

.logo {
  display: inline-flex;
}

.logo-text {
  margin-left: 5px;
}