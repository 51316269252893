.theme--dark .card-generator-form input {
    color: #FFFFFF;
    background: #8C8C8C;
}

.theme--dark label, .theme--dark .icon p {
    color: #FFFFFF;
}

.theme--dark .checkbox {
    color: #F0E68C;
}

.color-picker {
    margin: 0 auto;
}

.pagination {
    display: flex;
    justify-content: center;
}

.MuiCard-root{
    display: flex;
    flex-direction: column;
}
.MuiCardActions-root{
    margin-top: auto;
}

.ReactCrop.ReactCrop--locked {
    overflow: scroll;
}

.ReactCrop__child-wrapper {
    width: max-content;
}