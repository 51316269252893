.link {
  color: #FFA500;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link1 {
  color: #C0C0C0;
  text-decoration: none;
}

.link1:hover {
  text-decoration: underline;
}

.splash {
  width: 80%;
  margin: 0 auto;
  margin-top: 1em;
}

.splash__stack {
  margin-bottom: 2em;
}

@media screen and (max-width: 1040px) {
  .splash__stack {
    flex-direction: column !important;
  }

  .splash__card {
    width: 100%;
    margin: 1em 0 !important;
  }
}
@media screen and (min-width: 1041px) {
  .splash__stack {
    flex-direction: row !important;
  }

  .splash__card {
    width: 33%;
    margin: 0 0.5em !important;
  }
}